@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.image-overlay {
  box-shadow: inset 0 0 0 1000px rgb(76 76 120 / 95%);
}

.hero-overlay {
  box-shadow: inset 0 0 0 1000px rgb(76 76 120 / 75%);
}
 
.carousel .control-dots {
  position: absolute;
  bottom: 0;
  margin: -35px 0;
  padding: 5px;
  text-align: center;
  z-index: 1;
}

.carousel.carousel-slider {
  position: relative;
  margin: 0;
  overflow: visible;
}